import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'store';
import { getUserToken } from 'api';
import { setWithExpiry } from 'helper';

interface IGeneralStore {
	loading: boolean;
}

const initialState: IGeneralStore = {
	loading: false,
};

export const generalSlice = createSlice({
	name: 'general',
	initialState,
	reducers: {
		setLoading: (state: IGeneralStore, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
	},
});

export const generalActions = generalSlice.actions;
export const generalReducer = generalSlice.reducer;

export const login =
	({ username, password }: { username: string; password: string }) =>
	async (dispatch: AppDispatch) => {
		dispatch(generalActions.setLoading(true));
		const { success, error, accessToken } = await getUserToken({ username, password });
		if (success) {
			setWithExpiry('JWTToken', accessToken, 1000 * 60 * 60 * 24)
		} else localStorage.removeItem('JWTToken');
		dispatch(generalActions.setLoading(false));
		return { success, error };
	};
