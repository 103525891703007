import { useEffect, useState } from 'react';
import { collectionsActions, getCollection } from 'store/reducers/collections';
import { useAppDispatch, useAppSelector, useQueryParam } from 'hooks';
import { tCollectionType } from 'types';
import { Manual } from './Manual';
import { Automatic } from './Automatic';

export const Collection = () => {
	const dispatch = useAppDispatch();

	const query = useQueryParam();
	const id = query.get('id');
	const { collection } = useAppSelector(state => state.collectionsStore);
	const [collectionType, setCollectionType] = useState<tCollectionType>(
		query.get('type') as tCollectionType
	);

	useEffect(() => {
		(async () => {
			if (id) {
				const res = await dispatch(getCollection({ id }));
				setCollectionType(res.type);
			}
		})();
		return () => {
			dispatch(collectionsActions.setCollection(null));
		};
	}, [id]);

	const renderContent = () => {
		switch (collectionType) {
			case 'MANUAL': {
				return <Manual id={id} collection={collection} />;
			}
			case 'AUTOMATIC': {
				return <Automatic id={id} collection={collection} />;
			}
		}
	};

	return <>{renderContent()}</>;
};
