import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Save as SaveIcon,
	KeyboardBackspace as KeyboardBackspaceIcon,
	Search as SearchIcon,
} from '@mui/icons-material';
import { createManualCollection, updateManualCollection } from 'store/reducers/collections';
import {
	tCollectionSort,
	tCollectionSize,
	tOrder,
	IAddress,
	ICollection,
	ICollectionDefault,
	tShowPage,
} from 'types';
import { getSearchAddress } from 'api';
import { Token } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ROUTES } from 'constant';
import { useSnackbar } from 'notistack';

import { sizes, orders, sortingFieldsManual, initialFiltes, showOnPage } from '../data';

export const Manual = ({
	id,
	collection,
}: {
	id: string | null;
	collection: ICollection | null;
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const { loading } = useAppSelector(state => state.generalStore);
	const [data, setData] = useState<ICollectionDefault>({
		name: '',
		description: '',
		type: 'MANUAL',
		size: 'S',
		sortSetting: {
			field: 'txns_buys_m5',
			order: 'asc',
		},
		filters: initialFiltes,
		tokenLimits: 0,
		showPage: 'EXPLORE',
	});
	const [currentToken, setCurrentToken] = useState<{ val: string; loading: boolean }>({
		val: '',
		loading: false,
	});
	const [tokens, setTokens] = useState<IAddress[]>([]);

	const verifyToken = async () => {
		setCurrentToken(prev => {
			return { ...prev, loading: true };
		});
		const { data, error } = await getSearchAddress({ address: currentToken.val });
		if (error) {
			enqueueSnackbar(error, {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
		} else {
			const newTokens = [...tokens];
			let isNew = true;
			newTokens.forEach(token => {
				if (token.address === data.address) isNew = false;
			});
			if (isNew) newTokens.push(data);
			else
				enqueueSnackbar(`The '${data.name}' token is already in the collection`, {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'center',
						vertical: 'top',
					},
				});
			setTokens(newTokens);
		}
		setCurrentToken({ val: '', loading: false });
	};

	const deleteToken = (address: string) => {
		const newTokens = tokens.filter(el => el.address !== address);
		setTokens(newTokens);
	};

	const publish = async () => {
		const newTokens: string[] = tokens.map(el => {
			return el.pairAddress;
		});
		const { success, message } = id
			? await dispatch(updateManualCollection({ id, collection: data, pairs: newTokens }))
			: await dispatch(createManualCollection({ collection: data, pairs: newTokens }));

		if (success) {
			enqueueSnackbar(message, {
				variant: 'success',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
			navigate(ROUTES.COLLECTIONS);
		} else
			enqueueSnackbar(message, {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
	};

	useEffect(() => {
		if (collection) {
			setData(collection);
			setTokens(collection.tokens || []);
		}
	}, [collection]);

	return (
		<Grid
			container
			spacing={0}
			columns={14}
			sx={{
				padding: 0,
				flexGrow: 1,
				minHeight: '100vh',
			}}
		>
			<Grid item xs={6} sx={{ background: '#FFF', borderRight: '1px solid #C1C7CD' }}>
				<Box component="form" noValidate autoComplete="off">
					<Box
						sx={theme => ({
							display: 'flex',
							flexDirection: 'column',
							gap: '24px 0',
							background: '#FFF',
							padding: '16px 24px 32px',
						})}
					>
						<Button
							sx={{ justifyContent: 'flex-start', gap: '0 10px', padding: 0 }}
							onClick={() => navigate(ROUTES.COLLECTIONS)}
						>
							<KeyboardBackspaceIcon /> Back to Collections
						</Button>
						<Typography variant="h6" fontWeight={700}>
							Settings
						</Typography>
						<FormControl fullWidth>
							<TextField
								id="name"
								label="Collection name"
								size="medium"
								value={data.name}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setData(prev => {
										return { ...prev, name: e.target.value };
									});
								}}
							/>
						</FormControl>
						<FormControl fullWidth>
							<TextField
								id="outlined-multiline-static"
								label="Description"
								multiline
								minRows={2}
								maxRows={3}
								error={
									data.description && data.description.length > 300 ? true : false
								}
								helperText={
									data.description && data.description.length > 300
										? 'Max 300 characters'
										: ''
								}
								value={data.description}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setData(prev => {
										return { ...prev, description: e.target.value };
									});
								}}
							/>
						</FormControl>
						<Stack display="flex" direction="row" flexWrap="nowrap" gap="16px">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="card-size">Card size</InputLabel>
								<Select
									labelId="card-size"
									value={data.size}
									label="Card size"
									onChange={e =>
										setData(prev => {
											return {
												...prev,
												size: e.target.value as tCollectionSize,
											};
										})
									}
								>
									{sizes.map(el => {
										return (
											<MenuItem key={el.key} value={el.key}>
												{el.title}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="show-on">Show on page</InputLabel>
								<Select
									labelId="show-on"
									value={data.showPage}
									label="Show on page"
									onChange={e =>
										setData(prev => {
											return {
												...prev,
												showPage: e.target.value as tShowPage,
											};
										})
									}
								>
									{showOnPage.map(el => {
										return (
											<MenuItem key={el.key} value={el.key}>
												{el.title}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Stack>
						<Stack display="flex" direction="row" flexWrap="nowrap" gap="16px">
							<FormControl sx={{ flex: 1 }}>
								<InputLabel id="sort">Sort by</InputLabel>
								<Select
									labelId="sort"
									value={data.sortSetting.field}
									label="Sort By"
									onChange={e =>
										setData(prev => {
											return {
												...prev,
												sortSetting: {
													field: e.target.value as tCollectionSort,
													order: prev.sortSetting.order,
												},
											};
										})
									}
								>
									{sortingFieldsManual.map(el => {
										return (
											<MenuItem key={el.key} value={el.key}>
												{el.title}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControl sx={{ flex: 0.3 }}>
								<InputLabel id="order">Rank by</InputLabel>
								<Select
									labelId="order"
									id="order"
									value={data.sortSetting.order}
									label="Order"
									onChange={e =>
										setData(prev => {
											return {
												...prev,
												sortSetting: {
													field: prev.sortSetting.field,
													order: e.target.value as tOrder,
												},
											};
										})
									}
								>
									{orders.map(el => {
										return (
											<MenuItem key={el.key} value={el.key}>
												{el.title}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Stack>
					</Box>
					<Divider />
					<Box
						sx={theme => ({
							display: 'flex',
							flexDirection: 'column',
							gap: '16px 0',
							background: '#FFF',
							padding: '24px 24px 16px',
						})}
					>
						<Typography variant="h6" fontWeight={600}>
							Add Tokens
						</Typography>
						<Stack display="flex" direction="row" flexWrap="nowrap" gap="16px">
							<FormControl sx={{ flex: 1 }}>
								<TextField
									id="token"
									label="New Token"
									size="medium"
									value={currentToken.val}
									disabled={currentToken.loading}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setCurrentToken({ val: e.target.value, loading: false })
									}
								/>
							</FormControl>
							<Button
								variant="contained"
								size="medium"
								disabled={!currentToken.val || currentToken.loading}
								onClick={verifyToken}
							>
								Add Token
							</Button>
						</Stack>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={8} sx={theme => ({ background: theme.palette.secondary.main })}>
				<Box
					sx={theme => ({
						background: theme.palette.secondary.main,
						padding: 2,
						flex: 1,
					})}
				>
					<Stack direction="row" justifyContent="flex-end" sx={{ marginBottom: 2 }}>
						<LoadingButton
							variant="contained"
							size="large"
							disabled={!tokens?.length || !data.name}
							loading={loading}
							loadingPosition="start"
							startIcon={<SaveIcon />}
							sx={{ gap: '0 4px' }}
							onClick={publish}
						>
							{id ? 'Update' : 'Publish'}
						</LoadingButton>
					</Stack>
					<Box>
						{tokens?.length > 0 ? (
							tokens.map(el => {
								return (
									<Token
										key={el.address}
										token={el}
										onDelete={() => deleteToken(el.address)}
									/>
								);
							})
						) : (
							<Stack
								height={400}
								alignItems="center"
								justifyContent="center"
								gap="12px 0"
							>
								<SearchIcon sx={{ width: '50px', height: 'auto' }} />
								<Typography variant="body1">
									You don't have any tokens added yet
								</Typography>
							</Stack>
						)}
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
