import { combineReducers } from '@reduxjs/toolkit';
import { generalReducer } from '../slices';

import { collectionsReducer } from './collections';


export const rootReducer = combineReducers({
    generalStore: generalReducer,
    collectionsStore: collectionsReducer,
});
