import axios, { AxiosHeaders } from 'axios';
import { getWithExpiry } from 'helper';

export const apiClient = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
});

apiClient.interceptors.request.use(
	config => {
		const token = getWithExpiry('JWTToken');

		if (token && config.url) {
			if (!config.headers) config.headers = {} as AxiosHeaders;
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	undefined,
	{
		synchronous: true,
	}
);

apiClient.interceptors.response.use(
	response => {
		return response;
	},
	function (error) {
		if (error.response.status === 403) {
			localStorage.removeItem('JWTToken');
			window.location.pathname = '/login';
		}
		return Promise.reject(error.response);
	}
);
