import * as React from 'react';
import { Box, styled } from '@mui/system';
import {
	Unstable_NumberInput as BaseNumberInput,
	NumberInputProps,
	numberInputClasses,
} from '@mui/base/Unstable_NumberInput';

interface IProps extends NumberInputProps {
	changeVal: (val: number | null) => void;
}

const NumberInput = React.forwardRef(function CustomNumberInput(
	props: IProps,
	ref: React.ForwardedRef<HTMLDivElement>
) {
	const { changeVal } = props;
	const [value, setValue] = React.useState<number | null>(null);
	const changeValue = (value: number | null) => {
		setValue(value);
		changeVal(value);
	};
	React.useEffect(() => {
    setValue(props.value || null);
	}, [props.value]);
	return (
		<BaseNumberInput
			slots={{
				root: InputRoot,
				input: InputElement,
				incrementButton: Button,
				decrementButton: Button,
			}}
			slotProps={{
				incrementButton: {
					children: <span className="arrow">▴</span>,
				},
				decrementButton: {
					children: <span className="arrow">▾</span>,
				},
			}}
			{...props}
			value={value}
			onChange={(event, value) => changeValue(value)}
			ref={ref}
		/>
	);
});

export default function NumberInputAdornments(props: IProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: { xs: 'column', sm: 'row' },
				gap: 2,
			}}
		>
			<NumberInput
				{...props}
				startAdornment={<InputAdornment>{props.startAdornment}</InputAdornment>}
				endAdornment={<InputAdornment>{props.endAdornment}</InputAdornment>}
			/>
		</Box>
	);
}

const InputAdornment = styled('div')(
	({ theme }) => `
  padding: 4px;
  min-width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? grey[500] : grey[700]};
  background: ${theme.palette.mode === 'dark' ? grey[50] : grey[50]};
  font-size: 14px;
  &:empty{
    padding: 0;
    min-width: 0;
  }
`
);

const blue = {
	100: '#DAECFF',
	200: '#B6DAFF',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E5',
	700: '#0059B2',
	900: '#003A75',
};

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
};

const InputRoot = styled('div')(
	({ theme }) => `
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
		theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  display: grid;
  grid-template-columns: auto 1fr auto 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  padding: 0;

  &.${numberInputClasses.focused} {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const InputElement = styled('input')(
	({ theme }) => `
  min-width: 80px;
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px;
  outline: 0;
`
);

const Button = styled('button')(
	({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 20px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 4/5;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 4/5;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  }

  & .arrow {
    transform: translateY(-1px);
  }

  & .arrow {
    transform: translateY(-1px);
  }
`
);
