import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useQueryParam() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}
