import { ICollectionDefault, tCollectionSort, tFilters, tOrder, tShowPage } from 'types';
import { apiClient } from './apiClient';
import { API_ROUTES } from 'constant';

export const getCollectionsApi = async ({
	currentPage,
	perPage,
	showPage,
}: {
	currentPage: number;
	perPage: number;
	showPage: tShowPage;
}) => {
	try {
		const { data } = await apiClient.get(
			`${API_ROUTES.COLLECTIONS}?page=${currentPage}&size=${perPage}&showPage=${showPage}`
		);
		return data;
	} catch (err) {
		return null;
	}
};

export const getSearchAddress = async ({ address }: { address: string }) => {
	try {
		const { data } = await apiClient.get(`${API_ROUTES.SEARCH_ADDRESS}?address=${address}`);
		return { data, error: null };
	} catch (err: any) {
		return { error: err?.data?.error };
	}
};

export const getFilteredTokens = async ({
	filters,
	sortSetting,
}: {
	filters: tFilters;
	sortSetting: {
		field: tCollectionSort;
		order: tOrder;
	};
}) => {
	const filteredObj = Object.fromEntries(
		Object.entries(filters).filter(([key, value]) => value && value > 0)
	);
	try {
		const { data } = await apiClient.post(`${API_ROUTES.SEARCH_BY_FILTERS}`, {
			filters: filteredObj,
			sortSetting,
		});
		return { tokens: data.tokens, error: null };
	} catch (err: any) {
		return { tokens: [], error: err?.data?.error };
	}
};

export const deleteCollectionApi = async ({ id }: { id: string }) => {
	try {
		const { data } = await apiClient.delete(`${API_ROUTES.COLLECTIONS}/${id}`);
		return { success: true, message: data.result };
	} catch (err: any) {
		return { success: false, message: err?.data?.error };
	}
};

export const postManualCollectionApi = async ({
	collection,
	pairs,
}: {
	collection: ICollectionDefault;
	pairs: string[];
}) => {
	const { name, description, type, size, sortSetting, showPage } = collection;
	try {
		await apiClient.post(`${API_ROUTES.COLLECTIONS}`, {
			name,
			description: description || null,
			type,
			size,
			sortSetting,
			pairs,
			showPage,
		});
		return { success: true, message: 'Collection was successfully created.' };
	} catch (err: any) {
		return { success: false, message: err?.data?.error };
	}
};
export const putManualCollectionApi = async ({
	id,
	collection,
	pairs,
}: {
	id: string;
	collection: ICollectionDefault;
	pairs: string[];
}) => {
	const { name, description, type, size, sortSetting, showPage } = collection;
	try {
		await apiClient.put(`${API_ROUTES.COLLECTIONS}/${id}`, {
			name,
			description: description || null,
			type,
			size,
			sortSetting,
			pairs,
			showPage,
		});
		return { success: true, message: 'Collection was successfully updated.' };
	} catch (err: any) {
		return { success: false, message: err?.data?.error };
	}
};

export const postAutomaticCollectionApi = async ({
	collection,
}: {
	collection: ICollectionDefault;
}) => {
	const { name, description, type, size, sortSetting, filters, tokenLimits, showPage } =
		collection;
	try {
		await apiClient.post(`${API_ROUTES.COLLECTIONS}`, {
			name,
			description: description || null,
			type,
			size,
			sortSetting,
			filters,
			tokenLimits,
			showPage,
		});
		return { success: true, message: 'Collection was successfully created.' };
	} catch (err: any) {
		return { success: false, message: err?.data?.error };
	}
};
export const putAutomaticlCollectionApi = async ({
	id,
	collection,
}: {
	id: string;
	collection: ICollectionDefault;
}) => {
	const { name, description, type, size, sortSetting, filters, tokenLimits, showPage } =
		collection;

	try {
		await apiClient.put(`${API_ROUTES.COLLECTIONS}/${id}`, {
			name,
			description: description || null,
			type,
			size,
			sortSetting,
			filters,
			tokenLimits,
			showPage,
		});
		return { success: true, message: 'Collection was successfully updated.' };
	} catch (err: any) {
		return { success: false, message: err?.data?.error };
	}
};

export const putCollectionToggleApi = async ({ id }: { id: string }) => {
	try {
		const { data } = await apiClient.put(`${API_ROUTES.COLLECTIONS}/${id}/toggle`);
		return { success: true, message: data.result };
	} catch (err: any) {
		return { success: false, message: err?.data?.error };
	}
};

export const getCollectionApi = async ({ id }: { id: string }) => {
	try {
		const { data } = await apiClient.get(`${API_ROUTES.COLLECTIONS}/${id}`);
		return data;
	} catch (err) {
		return null;
	}
};

export const getUserToken = async ({
	username,
	password,
}: {
	username: string;
	password: string;
}) => {
	try {
		const { data } = await apiClient.post(`${API_ROUTES.LOGIN}`, { username, password });
		return { success: true, accessToken: data.accessToken };
	} catch (err: any) {
		return { error: err?.data?.error, success: false };
	}
};

export const putCollectionsApi = async ({
	currentPage,
	perPage,
	collections,
	showPage,
}: {
	currentPage: number;
	perPage: number;
	collections: string[];
	showPage: tShowPage;
}) => {
	try {
		const { data } = await apiClient.put(`${API_ROUTES.COLLECTIONS_ORDER}`, {
			page: currentPage,
			size: perPage,
			collections,
			showPage,
		});
		return { success: true, message: data.result };
	} catch (err: any) {
		return { message: err?.data?.error, success: false };
	}
};
