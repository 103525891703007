export type tCollectionType = 'MANUAL' | 'AUTOMATIC';
export type tCollectionSize = 'S' | 'M' | 'L';
export type tShowPage = 'EXPLORE' | 'HOMEPAGE';

export enum eCollectionType {
	MANUAL = 'Manual coin collection',
	AUTOMATIC = 'Automatic coin collection',
}
export enum eCollectionSize {
	S,
	M,
	L,
}
export type tCollectionSort =
	| 'txns_buys_m5'
	| 'txns_buys_h1'
	| 'txns_buys_h6'
	| 'txns_buys_h24'
	| 'txns_sells_m5'
	| 'txns_sells_h1'
	| 'txns_sells_h6'
	| 'txns_sells_h24'
	| 'volume_m5'
	| 'volume_h1'
	| 'volume_h6'
	| 'volume_h24'
	| 'priceChange_m5'
	| 'priceChange_h1'
	| 'priceChange_h6'
	| 'priceChange_h24'
	| 'priceUsd'
	| 'fdv'
	| 'liquidity'
	| 'market_cap'
	| 'pair_age'
	| 'txns_total_h24'
	| 'trending_m5'
	| 'trending_h1'
	| 'trending_h6'
	| 'trending_h24';

export type tOrder = 'asc' | 'desc';
export type tFilter =
	| 'Liq'
	| 'Fdv'
	| 'MarketCap'
	| 'Age'
	| '24HTxns'
	| '24HBuys'
	| '24HSells'
	| '24HVol'
	| '24HChg'
	| '6HTxns'
	| '6HBuys'
	| '6HSells'
	| '6HVol'
	| '6HChg'
	| '1HTxns'
	| '1HBuys'
	| '1HSells'
	| '1HVol'
	| '1HChg'
	| '5MTxns'
	| '5MBuys'
	| '5MSells'
	| '5MVol'
	| '5MChg';
export interface ICollectionDefault {
	name: string;
	description: string;
	type: tCollectionType;
	size: tCollectionSize;
	sortSetting: {
		field: tCollectionSort;
		order: tOrder;
	};
	filters: tFilters;
	tokenLimits: number;
	showPage: tShowPage;
}
export type tFilters = {
	minLiq: number | null;
	maxLiq: number | null;
	minFdv: number | null;
	maxFdv: number | null;
	maxMarketCap: number | null;
	minMarketCap: number | null;
	minAge: number | null;
	maxAge: number | null;
	min24HTxns: number | null;
	max24HTxns: number | null;
	min24HBuys: number | null;
	max24HBuys: number | null;
	min24HSells: number | null;
	max24HSells: number | null;
	min24HVol: number | null;
	max24HVol: number | null;
	min24HChg: number | null;
	max24HChg: number | null;
	min6HTxns: number | null;
	max6HTxns: number | null;
	min6HBuys: number | null;
	max6HBuys: number | null;
	min6HSells: number | null;
	max6HSells: number | null;
	min6HVol: number | null;
	max6HVol: number | null;
	min6HChg: number | null;
	max6HChg: number | null;
	min1HTxns: number | null;
	max1HTxns: number | null;
	min1HBuys: number | null;
	max1HBuys: number | null;
	min1HSells: number | null;
	max1HSells: number | null;
	min1HVol: number | null;
	max1HVol: number | null;
	min1HChg: number | null;
	max1HChg: number | null;
	min5MTxns: number | null;
	max5MTxns: number | null;
	min5MBuys: number | null;
	max5MBuys: number | null;
	min5MSells: number | null;
	max5MSells: number | null;
	min5MVol: number | null;
	max5MVol: number | null;
	min5MChg: number | null;
	max5MChg: number | null;
};
export interface ICollection extends ICollectionDefault {
	id: string;
	totalVolume: {
		h1: number;
		h6: number;
		h24: number;
		m5: number;
	} | null;
	tokens: IAddress[];
	active: boolean;
}
export interface IAddress {
	name: string;
	address: string;
	symbol: string;
	dexId: string;
	priceUsd: number;
	liquidityUsd: number;
	fdv: number;
	imageUrl: string;
	created: string;
	lastUpdated: string;
	pairAddress: string;
	txns: {
		m5: {
			buys: number;
			sells: number;
		};
		h1: {
			buys: number;
			sells: number;
		};
		h6: {
			buys: number;
			sells: number;
		};
		h24: {
			buys: number;
			sells: number;
		};
	};
	volume: {
		m5: number;
		h1: number;
		h6: number;
		h24: number;
	};
	priceChange: {
		m5: number;
		h1: number;
		h6: number;
		h24: number;
	};
}
