import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from 'hooks';
import { login } from 'store/slices';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constant';

export const Login = () => {
	const { loading } = useAppSelector(state => state.generalStore);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const [data, setData] = useState({ username: '', password: '' });
	const loginUser = async () => {
		const { error, success } = await dispatch(login(data));
		if (!success) {
			enqueueSnackbar(error, {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
		} else navigate(ROUTES.COLLECTIONS);
	};
	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			spacing={0}
			columns={14}
			sx={{
				padding: 0,
				flexGrow: 1,
				minHeight: '100vh',
			}}
		>
			<Box component="form" noValidate autoComplete="off">
				<Stack
					flexDirection={'row'}
					alignItems="center"
					justifyContent="center"
					my={2}
					gap={1}
				>
					<img
						src={'/token-icon-stub.svg'}
						alt="logo"
						style={{
							width: '30px',
							height: '30px',
							objectFit: 'contain',
							borderRadius: 50,
						}}
					/>
					<Typography variant="h5" sx={{ fontWeight: 600 }}>
						Login
					</Typography>
				</Stack>
				<TextField
					margin="normal"
					
					required
					fullWidth
					id="username"
					label="User Name"
					name="username"
					focused
					value={data.username}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setData(prev => {
							return { ...prev, username: e.target.value };
						});
					}}
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					focused
					autoComplete="current-password"
					value={data.password}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setData(prev => {
							return { ...prev, password: e.target.value };
						});
					}}
				/>
				<Stack alignItems="center" justifyContent="center" my={2}>
					<LoadingButton
						variant="contained"
						size="large"
						disabled={!data.username || !data.password}
						loading={loading}
						loadingPosition="start"
						sx={{ gap: '0 4px', width: '100%' }}
						onClick={loginUser}
					>
						Login
					</LoadingButton>
				</Stack>
			</Box>
		</Grid>
	);
};
