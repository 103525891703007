import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
	palette: {
		background: {
			default: '#F2F4F8',
		},
		primary: {
			main: '#1d1d22',
			light: '#2e2e33',
		},
		secondary: {
			main: '#F2F4F8',
			light: '#414141'
		},
		error: {
			main: red.A400,
		},
		text: {
			primary: '#21272A',
		},
		success: {
			main: '#008000',
		},
	},
	typography: {
		fontSize: 16,
		h2: {
			fontSize: '2.4rem',
			'@media (min-width:600px)': {
				fontSize: '2rem',
			},
		},
	},
});

export default theme;
