import { tCollectionSort, tCollectionSize, tFilter, tOrder } from 'types';

export const sizes: { key: tCollectionSize; title: string }[] = [
	{
		key: 'S',
		title: 'S',
	},
	{
		key: 'M',
		title: 'M',
	},
	{
		key: 'L',
		title: 'L',
	},
];
export const orders: { key: tOrder; title: string }[] = [
	{
		key: 'asc',
		title: 'Ascending',
	},
	{
		key: 'desc',
		title: 'Descending',
	},
];

export const showOnPage: { key: 'EXPLORE' | 'HOMEPAGE'; title: string }[] = [
	{
		key: 'EXPLORE',
		title: 'Explore page',
	},
	{
		key: 'HOMEPAGE',
		title: 'Home page',
	},
];

export const sortingFieldsAutomatic: { key: tCollectionSort; title: string }[] = [
	{ key: 'txns_buys_h24', title: 'Buys 24H' },
	{ key: 'txns_sells_h24', title: 'Sells 24H' },
	{ key: 'liquidity', title: 'Liquidity' },
	{ key: 'fdv', title: 'FDV' },
	{ key: 'market_cap', title: 'Market Cap' },
	{ key: 'priceUsd', title: 'Price USD' },
	{ key: 'txns_total_h24', title: 'Total 24H' },
	{ key: 'pair_age', title: 'Pair Age' },
	{ key: 'trending_m5', title: 'Trending 5M' },
	{ key: 'trending_h1', title: 'Trending 1H' },
	{ key: 'trending_h6', title: 'Trending 6H' },
	{ key: 'trending_h24', title: 'Trending 24H' },
];

export const sortingFieldsManual: { key: tCollectionSort; title: string }[] = [
	{ key: 'txns_buys_h24', title: 'Buys 24H' },
	{ key: 'txns_sells_h24', title: 'Sells 24H' },
	{ key: 'liquidity', title: 'Liquidity' },
	{ key: 'fdv', title: 'FDV' },
	{ key: 'priceUsd', title: 'Price USD' },
	{ key: 'txns_buys_m5', title: 'Buys 5M' },
	{ key: 'txns_buys_h1', title: 'Buys 1H' },
	{ key: 'txns_buys_h6', title: 'Buys 6H' },
	{ key: 'txns_sells_m5', title: 'Sells 5M' },
	{ key: 'txns_sells_h1', title: 'Sells 1H' },
	{ key: 'txns_sells_h6', title: 'Sells 6H' },
	{ key: 'volume_m5', title: 'Volume 5M' },
	{ key: 'volume_h1', title: 'Volume 1H' },
	{ key: 'volume_h6', title: 'Volume 6H' },
	{ key: 'volume_h24', title: 'Volume 24H' },
	{ key: 'priceChange_m5', title: '5M price change' },
	{ key: 'priceChange_h1', title: '1H price change' },
	{ key: 'priceChange_h6', title: '6H price change' },
	{ key: 'priceChange_h24', title: '24H price change' },
];

export const filtersFields: {
	key: tFilter;
	title: string;
	startSymbol?: string;
	endSymbol?: string;
}[] = [
	{
		title: 'Liquidity',
		key: 'Liq',
		startSymbol: '$',
	},
	{
		title: 'FDV',
		key: 'Fdv',
		startSymbol: '$',
	},
	{
		title: 'Market cap',
		key: 'MarketCap',
		startSymbol: '$',
	},
	{
		title: 'Pair age',
		key: 'Age',
		endSymbol: 'h',
	},
	{
		title: '24H txns',
		key: '24HTxns',
	},
	{
		title: '24H buys',
		key: '24HBuys',
	},
	{
		title: '24H sells',
		key: '24HSells',
	},
	{ title: '24H volume', key: '24HVol', startSymbol: '$' },
	{ title: '24H change', key: '24HChg', endSymbol: '%' },
	{ title: '6H txns', key: '6HTxns' },
	{ title: '6H buys', key: '6HBuys' },
	{ title: '6H sells', key: '6HSells' },
	{ title: '6H volume', key: '6HVol', startSymbol: '$' },
	{ title: '6H change', key: '6HChg', endSymbol: '%' },
	{ title: '1H txns', key: '1HTxns' },
	{ title: '1H buys', key: '1HBuys' },
	{ title: '1Hsells', key: '1HSells' },
	{ title: '1H volume', key: '1HVol', startSymbol: '$' },
	{ title: '1H change', key: '1HChg', endSymbol: '%' },
	{ title: '5M txns', key: '5MTxns' },
	{ title: '5M buys', key: '5MBuys' },
	{ title: '5M sells', key: '5MSells' },
	{ title: '5M volume', key: '5MVol', startSymbol: '$' },
	{ title: '5M change', key: '5MChg', endSymbol: '%' },
];

export const initialFiltes = {
	minLiq: null,
	maxLiq: null,
	minFdv: null,
	maxFdv: null,
	minMarketCap: null,
	maxMarketCap: null,
	minAge: null,
	maxAge: null,
	min24HTxns: null,
	max24HTxns: null,
	min24HBuys: null,
	max24HBuys: null,
	min24HSells: null,
	max24HSells: null,
	min24HVol: null,
	max24HVol: null,
	min24HChg: null,
	max24HChg: null,
	min6HTxns: null,
	max6HTxns: null,
	min6HBuys: null,
	max6HBuys: null,
	min6HSells: null,
	max6HSells: null,
	min6HVol: null,
	max6HVol: null,
	min6HChg: null,
	max6HChg: null,
	min1HTxns: null,
	max1HTxns: null,
	min1HBuys: null,
	max1HBuys: null,
	min1HSells: null,
	max1HSells: null,
	min1HVol: null,
	max1HVol: null,
	min1HChg: null,
	max1HChg: null,
	min5MTxns: null,
	max5MTxns: null,
	min5MBuys: null,
	max5MBuys: null,
	min5MSells: null,
	max5MSells: null,
	min5MVol: null,
	max5MVol: null,
	min5MChg: null,
	max5MChg: null,
};
