import { ROUTES } from 'constant';
import { getWithExpiry } from 'helper';
import { Collections, Collection, Login } from 'pages';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';


const ProtectedRoute = () => {
    const token = getWithExpiry('JWTToken');
    return token ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />;
};

export const routes = createBrowserRouter([
    {
        path: ROUTES.COLLECTIONS,
        element: <ProtectedRoute />,
        children: [
            {
                path: ROUTES.COLLECTIONS,
				element: <Collections />
            },
			{
                path: ROUTES.COLLECTION,
				element: <Collection />
            },
        ],
    },
	{
		path: ROUTES.LOGIN,
		element: <Login />,
	},
]);
