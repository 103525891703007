import { Typography } from '@mui/material';

export const DetectPercent = ({ percent, variant }: { percent: number; variant?: 'body2' }) => {
	return (
		<Typography
			variant={variant}
			sx={theme => ({
				color:
					Math.sign(percent) > 0
						? theme.palette.success.main
						: Math.sign(percent) < 0
						? theme.palette.error.main
						: theme.palette.text.primary,
			})}
		>
			{percent.toFixed(2)}%
		</Typography>
	);
};

export const formatToUSD = (price: number, maximumFractionDigits?: number) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: maximumFractionDigits || 2,
	}).format(price);
};

export const formatCurrency = (old: number) => {
	let num = Math.floor(old);
	if (num >= 1000) {
		const suffixes = ['', 'K', 'M', 'B', 'T'];
		const index = Math.floor(Math.log10(Math.abs(num)) / 3);
		const shortValue = (num / Math.pow(1000, index)).toFixed(2);

		return `$${shortValue}${suffixes[index]}`;
	}
	return `$${num}`;
};

export function setWithExpiry(key: string, value: string, ttl: number) {
	const now = new Date();
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}
