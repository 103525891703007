export const COLUMNS: {
	align: 'inherit' | 'right' | 'left' | 'center' | 'justify' | undefined;
	size?: 'small' | 'medium';
	text: string;
}[] = [
	{
		align: 'left',
		text: '',
	},
	{
		align: 'left',
		text: 'Collection Name',
	},
	{
		align: 'center',
		size: 'small',
		text: 'Total Volume 5m',
	},
	{
		align: 'center',
		size: 'small',
		text: 'Total Volume 1h',
	},
	{
		align: 'center',
		size: 'small',
		text: 'Total Volume 6h',
	},
	{
		align: 'center',
		size: 'small',
		text: 'Total Volume 24h',
	},
	{
		align: 'center',
		text: 'Collection Type',
	},
	{
		align: 'center',
		size: 'small',
		text: 'Active',
	},
	{
		align: 'right',
		text: '',
	},
];
