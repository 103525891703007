import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Save as SaveIcon,
	KeyboardBackspace as KeyboardBackspaceIcon,
	Search as SearchIcon,
} from '@mui/icons-material';
import { createAutomaticCollection, updateAutomaticCollection } from 'store/reducers/collections';
import {
	tCollectionSize,
	tOrder,
	IAddress,
	ICollection,
	tCollectionSort,
	tFilters,
	ICollectionDefault,
	tShowPage,
} from 'types';
import { Token } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ROUTES } from 'constant';
import { useSnackbar } from 'notistack';
import { getFilteredTokens } from 'api';

import {
	sizes,
	orders,
	filtersFields,
	initialFiltes,
	sortingFieldsAutomatic,
	showOnPage,
} from '../data';
import NumberInputAdornments from 'components/NumberInputAdornments';

export const Automatic = ({
	id,
	collection,
}: {
	id: string | null;
	collection: ICollection | null;
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const { loading } = useAppSelector(state => state.generalStore);
	const [data, setData] = useState<ICollectionDefault>({
		name: '',
		description: '',
		type: 'AUTOMATIC',
		size: 'S',
		sortSetting: {
			field: 'txns_buys_h24',
			order: 'asc',
		},
		filters: initialFiltes,
		tokenLimits: 100,
		showPage: 'EXPLORE',
	});
	const [tokens, setTokens] = useState<IAddress[]>([]);
	const [loader, setLoader] = useState<boolean>(false);

	const handleFilter = async (key: string, value: number) => {
		setData(prev => {
			return { ...prev, filters: { ...prev.filters, [key]: value } };
		});
	};

	const isFilters = () => {
		const nonZeroValuesCount =
			(data.filters &&
				Object.values(data.filters).filter(value => value && value !== 0).length) ||
			0;
		return nonZeroValuesCount > 0;
	};
	const changeSorting = ({ field, order }: { field?: tCollectionSort; order?: tOrder }) => {
		setData(prev => {
			return {
				...prev,
				sortSetting: {
					field: field || prev.sortSetting.field,
					order: order || prev.sortSetting.order,
				},
			};
		});

		const canApply = isFilters();
		if (canApply) {
			apply({
				sorting: {
					field: field || data.sortSetting.field,
					order: order || data.sortSetting.order,
				},
			});
		}
	};
	const clear = () => {
		setData(prev => {
			return { ...prev, filters: initialFiltes };
		});
	};
	const apply = async ({ sorting }: { sorting?: { field: tCollectionSort; order: tOrder } }) => {
		setLoader(true);
		const { tokens, error } = await getFilteredTokens({
			filters: data.filters as tFilters,
			sortSetting: sorting ? sorting : data.sortSetting,
		});
		setLoader(false);
		if (error) {
			enqueueSnackbar(error, {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
		} else {
			setTokens(tokens);
		}
	};
	const publish = async () => {
		const { success, message } = id
			? await dispatch(updateAutomaticCollection({ id, collection: data }))
			: await dispatch(createAutomaticCollection({ collection: data }));

		if (success) {
			enqueueSnackbar(message, {
				variant: 'success',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
			navigate(ROUTES.COLLECTIONS);
		} else
			enqueueSnackbar(message, {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
	};

	useEffect(() => {
		if (collection) {
			setData(collection);
			setTokens(collection.tokens);
		}
	}, [collection]);

	return (
		<Grid
			container
			spacing={0}
			columns={14}
			sx={{
				padding: 0,
				flexGrow: 1,
				minHeight: '100vh',
			}}
		>
			<Grid item xs={6} sx={{ background: '#FFF', borderRight: '1px solid #C1C7CD' }}>
				<Box component="form" noValidate autoComplete="off">
					<Box
						sx={theme => ({
							display: 'flex',
							flexDirection: 'column',
							gap: '24px 0',
							background: '#FFF',
							padding: '16px 24px 32px',
						})}
					>
						<Button
							sx={{ justifyContent: 'flex-start', gap: '0 10px', padding: 0 }}
							onClick={() => navigate(ROUTES.COLLECTIONS)}
						>
							<KeyboardBackspaceIcon /> Back to Collections
						</Button>
						<Stack display="flex" direction="row" flexWrap="nowrap" gap="16px">
							<FormControl sx={{ flex: 1 }}>
								<TextField
									id="name"
									label="Collection name"
									size="medium"
									defaultValue={collection?.name}
									value={data.name}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setData(prev => {
											return { ...prev, name: e.target.value };
										});
									}}
								/>
							</FormControl>
							<FormControl sx={{ flex: 0.3 }}>
								<InputLabel id="card-size">Card size</InputLabel>
								<Select
									labelId="card-size"
									value={data.size}
									label="Card size"
									onChange={e =>
										setData(prev => {
											return {
												...prev,
												size: e.target.value as tCollectionSize,
											};
										})
									}
								>
									{sizes.map(el => {
										return (
											<MenuItem key={el.key} value={el.key}>
												{el.title}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Stack>
						<FormControl fullWidth>
							<InputLabel id="show-on">Show on page</InputLabel>
							<Select
								labelId="show-on"
								value={data.showPage}
								label="Show on page"
								onChange={e =>
									setData(prev => {
										return {
											...prev,
											showPage: e.target.value as tShowPage,
										};
									})
								}
							>
								{showOnPage.map(el => {
									return (
										<MenuItem key={el.key} value={el.key}>
											{el.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl fullWidth>
							<TextField
								id="outlined-multiline-static"
								label="Description"
								multiline
								minRows={2}
								maxRows={3}
								error={
									data.description && data.description.length > 300 ? true : false
								}
								helperText={
									data.description && data.description.length > 300
										? 'Max 300 characters'
										: ''
								}
								value={data.description}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setData(prev => {
										return { ...prev, description: e.target.value };
									});
								}}
							/>
						</FormControl>
					</Box>
					<Divider />
					<Box
						sx={theme => ({
							display: 'flex',
							flexDirection: 'column',
							gap: '16px 0',
							background: '#FFF',
							padding: '16px 24px 0',
							overflowY: 'scroll',
							maxHeight: '63vh',
						})}
					>
						<Stack
							display="flex"
							direction="row"
							justifyContent={'space-between'}
							flexWrap="nowrap"
							gap="16px"
						>
							<Typography variant="h6" fontWeight={600}>
								Filters
							</Typography>
							<Stack
								display="flex"
								direction="row"
								justifyContent={'space-between'}
								flexWrap="nowrap"
								gap="16px"
							>
								<Button
									variant="contained"
									color="primary"
									size="small"
									disabled={!isFilters() || loader || loading}
									onClick={() => apply({ sorting: data.sortSetting })}
								>
									Apply
								</Button>
								{/* <Button
									variant="contained"
									color="secondary"
									size="small"
									disabled={!isFilters() || loader || loading}
									onClick={clear}
								>
									Clear
								</Button> */}
							</Stack>
						</Stack>
						{filtersFields.map(filter => {
							return (
								<Stack
									key={filter.key}
									display="flex"
									direction="row"
									flexWrap="nowrap"
									alignItems={'center'}
									gap="16px"
								>
									<Typography variant="caption" sx={{ flex: 0.4 }}>
										{filter.title}
									</Typography>
									<FormControl sx={{ flex: 1 }}>
										<NumberInputAdornments
											id={`min${filter.key}`}
											min={1}
											max={data.filters[`max${filter.key}`] || undefined}
											value={data.filters[`min${filter.key}`]}
											placeholder="Min"
											startAdornment={filter.startSymbol}
											endAdornment={filter.endSymbol}
											changeVal={value =>
												handleFilter(`min${filter.key}`, Number(value))
											}
											onClick={e => e.preventDefault()}
										/>
									</FormControl>
									<FormControl sx={{ flex: 1 }}>
										<NumberInputAdornments
											id={`max${filter.key}`}
											min={data.filters[`min${filter.key}`] || 1}
											value={data.filters[`max${filter.key}`]}
											placeholder="Max"
											startAdornment={filter.startSymbol}
											endAdornment={filter.endSymbol}
											changeVal={value =>
												handleFilter(`max${filter.key}`, Number(value))
											}
											onClick={e => e.preventDefault()}
										/>
									</FormControl>
								</Stack>
							);
						})}
					</Box>
				</Box>
			</Grid>
			<Grid item xs={8} sx={theme => ({ background: theme.palette.secondary.main })}>
				<Box
					sx={theme => ({
						background: theme.palette.secondary.main,
						padding: '20px 16px 16px',
						flex: 1,
					})}
				>
					<Stack
						direction="row"
						justifyContent="flex-end"
						gap="0 12px"
						sx={{ marginBottom: 2 }}
					>
						<FormControl sx={{ flex: 0.5 }}>
							<InputLabel id="sort">Sort by</InputLabel>
							<Select
								labelId="sort"
								value={data.sortSetting.field}
								label="Sort By"
								onChange={e =>
									changeSorting({ field: e.target.value as tCollectionSort })
								}
							>
								{sortingFieldsAutomatic.map(el => {
									return (
										<MenuItem key={el.key} value={el.key}>
											{el.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl sx={{ flex: 0.3 }}>
							<InputLabel id="order">Rank by</InputLabel>
							<Select
								labelId="order"
								id="order"
								value={data.sortSetting.order}
								label="Order"
								onChange={e => changeSorting({ order: e.target.value as tOrder })}
							>
								{orders.map(el => {
									return (
										<MenuItem key={el.key} value={el.key}>
											{el.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<LoadingButton
							variant="contained"
							size="large"
							disabled={!tokens?.length || !data.name || loader}
							loading={loading}
							loadingPosition="start"
							startIcon={<SaveIcon />}
							sx={{ gap: '0 4px' }}
							onClick={publish}
						>
							{id ? 'Update' : 'Publish'}
						</LoadingButton>
					</Stack>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							maxHeight: '86vh',
						}}
					>
						{loader ? (
							<Stack
								height={400}
								alignItems="center"
								justifyContent="center"
								gap="12px 0"
							>
								<CircularProgress />
							</Stack>
						) : tokens?.length > 0 ? (
							<Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										overflowY: 'scroll',
										maxHeight: '76vh',
									}}
								>
									{tokens.map(el => {
										return <Token key={el.address} token={el} />;
									})}
								</Box>
								<Divider sx={{ margin: '12px 0' }} />
								<Stack
									display="flex"
									direction="row"
									flexWrap="nowrap"
									alignItems={'center'}
									gap="16px"
								>
									<Typography variant="body2">Tokens Limit</Typography>
									<NumberInputAdornments
										min={1}
										max={100}
										value={data.tokenLimits}
										placeholder="Tokens Limit"
										changeVal={value =>
											setData(prev => {
												return { ...prev, tokenLimits: value || 1 };
											})
										}
										onClick={e => e.preventDefault()}
									/>
								</Stack>
							</Box>
						) : (
							<Stack
								height={400}
								alignItems="center"
								justifyContent="center"
								gap="12px 0"
							>
								<SearchIcon sx={{ width: '50px', height: 'auto' }} />
								<Typography variant="body1">
									You don't have any tokens added yet
								</Typography>
							</Stack>
						)}
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
