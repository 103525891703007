import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../index';
import {
	deleteCollectionApi,
	getCollectionsApi,
	postManualCollectionApi,
	getCollectionApi,
	putManualCollectionApi,
	postAutomaticCollectionApi,
	putAutomaticlCollectionApi,
	putCollectionsApi,
	putCollectionToggleApi,
} from 'api';
import { generalActions } from '../../slices';
import { ICollection, ICollectionDefault, tShowPage } from 'types';

interface ICollectionStore {
	collections: ICollection[];
	currentPage: number;
	perPage: number;
	total: number;
	collection: ICollection | null;
	showPage: tShowPage;
}
const initialState: ICollectionStore = {
	collections: [],
	perPage: 10,
	currentPage: 0,
	total: 0,
	collection: null,
	showPage: 'EXPLORE',
};

export const collectionsSlice = createSlice({
	name: 'collections',
	initialState,
	reducers: {
		setCollections: (state: ICollectionStore, { payload }: PayloadAction<any>) => {
			state.collections = payload.collections;
			state.total = payload.totalElements;
			state.currentPage = payload.pageNumber;
			state.perPage = payload.size;
			state.showPage = payload.showPage;
		},
		setCollectionPage: (state: ICollectionStore, { payload }: PayloadAction<any>) => {
			state.currentPage = payload;
		},
		setCollectionPerPage: (state: ICollectionStore, { payload }: PayloadAction<any>) => {
			state.perPage = payload;
		},
		setCollection: (state: ICollectionStore, { payload }: PayloadAction<any>) => {
			state.collection = payload;
		},
	},
});

export const collectionsActions = collectionsSlice.actions;
export const collectionsReducer = collectionsSlice.reducer;

export const getCollections =
	({
		currentPage,
		perPage,
		showPage,
	}: {
		currentPage: number;
		perPage: number;
		showPage: tShowPage;
	}) =>
	async (dispatch: AppDispatch) => {
		const res = await getCollectionsApi({ currentPage, perPage, showPage });
		dispatch(collectionsActions.setCollections({ ...res, showPage }));
		dispatch(generalActions.setLoading(false));
	};

export const putCollectionsOrder =
	({
		currentPage,
		perPage,
		collections,
		showPage,
	}: {
		currentPage: number;
		perPage: number;
		collections: string[];
		showPage: tShowPage;
	}) =>
	async (dispatch: AppDispatch) => {
		const res = await putCollectionsApi({ currentPage, perPage, collections, showPage });
		return res;
	};

export const createManualCollection =
	({ collection, pairs }: { collection: ICollectionDefault; pairs: string[] }) =>
	async (dispatch: AppDispatch) => {
		dispatch(generalActions.setLoading(true));
		const res = await postManualCollectionApi({ collection, pairs });
		dispatch(generalActions.setLoading(false));
		return res;
	};

export const updateManualCollection =
	({ id, collection, pairs }: { id: string; collection: ICollectionDefault; pairs: string[] }) =>
	async (dispatch: AppDispatch) => {
		dispatch(generalActions.setLoading(true));
		const res = await putManualCollectionApi({ id, collection, pairs });
		dispatch(generalActions.setLoading(false));
		return res;
	};

export const createAutomaticCollection =
	({ collection }: { collection: ICollectionDefault }) =>
	async (dispatch: AppDispatch) => {
		dispatch(generalActions.setLoading(true));
		const res = await postAutomaticCollectionApi({ collection });
		dispatch(generalActions.setLoading(false));
		return res;
	};

export const updateAutomaticCollection =
	({ id, collection }: { id: string; collection: ICollectionDefault }) =>
	async (dispatch: AppDispatch) => {
		dispatch(generalActions.setLoading(true));
		const res = await putAutomaticlCollectionApi({ id, collection });
		dispatch(generalActions.setLoading(false));
		return res;
	};
export const deleteCollection =
	({ id }: { id: string }) =>
	async (dispatch: AppDispatch) => {
		dispatch(generalActions.setLoading(true));
		const res = await deleteCollectionApi({ id });
		dispatch(generalActions.setLoading(false));
		return res;
	};

export const activateCollection =
	({ id }: { id: string }) =>
	async (dispatch: AppDispatch) => {
		const res = await putCollectionToggleApi({ id });
		return res;
	};

export const getCollection =
	({ id }: { id: string }) =>
	async (dispatch: AppDispatch) => {
		const res = await getCollectionApi({ id });
		dispatch(collectionsActions.setCollection(res));
		dispatch(generalActions.setLoading(false));
		return res;
	};
