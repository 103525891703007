import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import {
	Button,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	IconButton,
	Chip,
	Container,
	Switch,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
} from '@mui/material';

import {
	Edit as EditIcon,
	Add as AddIcon,
	Delete as DeleteIcon,
	DragIndicator,
} from '@mui/icons-material';
import {
	getCollections,
	collectionsActions,
	deleteCollection,
	putCollectionsOrder,
	activateCollection,
} from 'store/reducers/collections';
import { eCollectionType, tCollectionType, tShowPage } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ActionDialog, SelectCollectionTypeModal } from 'components';
import { ROUTES } from 'constant';
import { formatToUSD } from 'helper';

import { COLUMNS } from './data';
import { showOnPage } from 'pages/Collection/data';

export const Collections = () => {
	const navigate = useNavigate();
	const { collections, currentPage, perPage, total, showPage } = useAppSelector(
		state => state.collectionsStore
	);

	console.log({ showPage });
	const { loading } = useAppSelector(state => state.generalStore);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openConfirm, setOpenConfirm] = useState<string | null>(null);
	const dispatch = useAppDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		dispatch(collectionsActions.setCollectionPage(newPage));
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		dispatch(collectionsActions.setCollectionPerPage(parseInt(event.target.value, 10)));
		dispatch(collectionsActions.setCollectionPage(0));
	};

	const getAllCollections = async ({
		currentPage,
		perPage,
		showPage,
	}: {
		currentPage: number;
		perPage: number;
		showPage: tShowPage;
	}) => {
		await dispatch(getCollections({ currentPage, perPage, showPage }));
	};

	const redirectToPage = ({ type, id }: { type: tCollectionType; id: string | null }) => {
		if (id) navigate(`${ROUTES.COLLECTION}?id=${id}`);
		else navigate(`${ROUTES.COLLECTION}?type=${type}`);
	};

	const deleteCollectionById = async () => {
		if (openConfirm) {
			const { success, message } = await dispatch(deleteCollection({ id: openConfirm }));
			setOpenConfirm(null);
			if (success) {
				enqueueSnackbar(message, {
					variant: 'success',
					anchorOrigin: {
						horizontal: 'center',
						vertical: 'top',
					},
				});
				await dispatch(getCollections({ currentPage: 0, perPage, showPage }));
			} else
				enqueueSnackbar(message, {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'center',
						vertical: 'top',
					},
				});
		}
	};
	const handleDragEnd = async (e: any) => {
		if (!e.destination) return;
		let tempData = Array.from(collections);
		let [source_data] = tempData.splice(e.source.index, 1);
		tempData.splice(e.destination.index, 0, source_data);
		dispatch(
			collectionsActions.setCollections({
				collections: tempData,
				pageNumber: currentPage,
				size: perPage,
				totalElements: total,
				showPage
			})
		);
		const { success, message } = await dispatch(
			putCollectionsOrder({
				collections: tempData.map(el => {
					return el.id;
				}),
				currentPage,
				perPage,
				showPage,
			})
		);
		if (!success) {
			enqueueSnackbar(message, {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
		}
	};

	const changeActiveState = async (id: string) => {
		const { success, message } = await dispatch(activateCollection({ id }));
		if (success) {
			enqueueSnackbar(message, {
				variant: 'success',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
			await getAllCollections({ currentPage, perPage, showPage });
		} else
			enqueueSnackbar(message, {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'center',
					vertical: 'top',
				},
			});
	};

	const changeCollectionsPage = async (page: tShowPage) => {
		getAllCollections({ currentPage: 0, perPage, showPage: page });
	};

	useEffect(() => {
		getAllCollections({ currentPage, perPage, showPage });
	}, [currentPage, perPage]);
	return (
		<>
			<Container className="App" maxWidth="xl" sx={{ my: 4 }}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{ mb: 4 }}
				>
					<Typography variant="h5" fontWeight="500">
						COLLECTIONS
					</Typography>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						gap={2}
					>
						<FormControl sx={{ flex: 1 }}>
							<InputLabel id="show-on-page">Show on page</InputLabel>
							<Select
								labelId="show-on-page"
								value={showPage}
								label="Show on page"
								onChange={e => changeCollectionsPage(e.target.value as tShowPage)}
							>
								{showOnPage.map(el => {
									return (
										<MenuItem key={el.key} value={el.key}>
											{el.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<Button
							variant="contained"
							size="large"
							sx={{ gap: '0 4px' }}
							onClick={() => setOpenModal(true)}
						>
							<AddIcon />
							New Collection
						</Button>
					</Stack>
				</Stack>
				<TableContainer component={Paper} sx={{ mb: 3 }}>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									{COLUMNS.map((column, idx) => {
										return (
											<TableCell
												key={`collection-column-${idx}`}
												align={column.align}
												size={column.size}
											>
												{column.text}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<Droppable droppableId="droppable-1">
								{provider => (
									<TableBody ref={provider.innerRef} {...provider.droppableProps}>
										{collections?.length > 0 ? (
											collections.map((row, index) => (
												<Draggable
													key={row.name}
													draggableId={row.name}
													index={index}
												>
													{provider => (
														<TableRow
															key={row.name}
															sx={{
																'&:last-child td, &:last-child th':
																	{
																		border: 0,
																	},
															}}
															{...provider.draggableProps}
															ref={provider.innerRef}
														>
															<TableCell
																component="th"
																scope="row"
																{...provider.dragHandleProps}
															>
																<DragIndicator />
															</TableCell>
															<TableCell component="th" scope="row">
																<Typography variant="body2">
																	{row.name}
																</Typography>
															</TableCell>
															<TableCell align="center" size="small">
																<Typography variant="body2">
																	{row.totalVolume != null
																		? formatToUSD(
																				row.totalVolume?.m5
																		  )
																		: '-'}
																</Typography>
															</TableCell>
															<TableCell align="center" size="small">
																<Typography variant="body2">
																	{row.totalVolume != null
																		? formatToUSD(
																				row.totalVolume?.h1
																		  )
																		: '-'}
																</Typography>
															</TableCell>
															<TableCell align="center" size="small">
																<Typography variant="body2">
																	{row.totalVolume != null
																		? formatToUSD(
																				row.totalVolume?.h6
																		  )
																		: '-'}
																</Typography>
															</TableCell>
															<TableCell align="center" size="small">
																<Typography variant="body2">
																	{row.totalVolume != null
																		? formatToUSD(
																				row.totalVolume?.h24
																		  )
																		: '-'}
																</Typography>
															</TableCell>
															<TableCell align="center">
																<Chip
																	label={
																		eCollectionType[row.type]
																	}
																	color="secondary"
																/>
															</TableCell>
															<TableCell>
																<Switch
																	color="success"
																	checked={row.active}
																	onClick={e =>
																		changeActiveState(row.id)
																	}
																/>
															</TableCell>
															<TableCell align="right" size="small">
																<Stack
																	direction="row"
																	justifyContent="flex-end"
																	gap="0 10px"
																>
																	<IconButton
																		aria-label="edit"
																		onClick={() =>
																			redirectToPage({
																				type: row.type,
																				id: row.id,
																			})
																		}
																	>
																		<EditIcon />
																	</IconButton>
																	<IconButton
																		aria-label="delete"
																		onClick={() =>
																			setOpenConfirm(row.id)
																		}
																	>
																		<DeleteIcon />
																	</IconButton>
																</Stack>
															</TableCell>
														</TableRow>
													)}
												</Draggable>
											))
										) : (
											<TableCell colSpan={7}>
												<Stack justifyContent="center" sx={{ height: 40 }}>
													<Typography align="center">NO DATA</Typography>
												</Stack>
											</TableCell>
										)}
										{provider.placeholder}
									</TableBody>
								)}
							</Droppable>
						</Table>
					</DragDropContext>
				</TableContainer>
				<TablePagination
					component="div"
					count={total}
					page={currentPage}
					onPageChange={handleChangePage}
					rowsPerPage={perPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
				<ActionDialog
					open={openConfirm != null}
					onClose={() => setOpenConfirm(null)}
					loading={loading}
					onAction={deleteCollectionById}
				/>
				<SelectCollectionTypeModal
					open={openModal}
					handleClose={() => setOpenModal(false)}
					chooseType={(val: tCollectionType) => redirectToPage({ type: val, id: null })}
				/>
			</Container>
		</>
	);
};
