import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

export const ActionDialog = ({
	open,
	loading,
	onClose,
	onAction,
}: {
	open: boolean;
	loading: boolean;
	onClose: () => void;
	onAction: () => void;
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<Typography
				textAlign="center"
				variant="h4"
				fontWeight="600"
				sx={{ padding: '40px 40px 0' }}
			>
				Delete
			</Typography>
			<DialogContent sx={{ padding: '4px 40px 32px', fontWeight: 500 }}>
				<Typography>Are you sure you want to delete this collection?</Typography>
			</DialogContent>
			<DialogActions
				sx={{ display: 'flex', justifyContent: 'center', padding: '0 40px 40px' }}
			>
				<Button
					variant="outlined"
					size="large"
					onClick={onClose}
					disabled={loading}
					sx={{ flex: 1, height: 60 }}
				>
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					size="large"
					color="error"
					loading={loading}
					loadingPosition="start"
					startIcon={<SaveIcon />}
					onClick={onAction}
					autoFocus
					sx={{ flex: 1, height: 60 }}
				>
					Delete
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
