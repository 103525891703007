import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Radio,
	Modal,
	RadioGroup,
	Stack,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { eCollectionType, tCollectionType } from 'types';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 450,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};
const items = [
	{ key: 'AUTOMATIC', text: eCollectionType.AUTOMATIC },
	{ key: 'MANUAL', text: eCollectionType.MANUAL },
];
export const SelectCollectionTypeModal = ({
	open,
	chooseType,
	handleClose,
}: {
	open: boolean;
	chooseType: (val: tCollectionType) => void;
	handleClose: () => void;
}) => {
	const [selectedType, setSelectedType] = useState<tCollectionType>('MANUAL');
	const onChange = (value: tCollectionType) => {
		setSelectedType(value);
	};
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Stack
					direction="row"
					gap="0 20px"
					justifyContent="center"
					sx={{ marginBottom: '32px' }}
				>
					<FormControl>
						<RadioGroup
							defaultValue="MANUAL"
							name="collectiontype"
							row
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '0 20px',
							}}
							onChange={(e, value) => onChange(value as tCollectionType)}
						>
							{items.map(el => {
								return (
									<FormControlLabel
										value={el.key}
										key={el.key}
										control={<Radio />}
										label={
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
													gap: '8px 0',
													width: 160,
													height: 150,
													border: '1px solid #DDE1E6',
												}}
											>
												<LibraryBooksIcon fontSize="large" />
												<Typography textAlign="center" fontWeight={600}>
													{el.text}
												</Typography>
											</Box>
										}
										sx={{
											display: 'flex',
											flexDirection: 'column-reverse',
											justifyContent: 'center',
											alignItems: 'center',
											margin: 0,
										}}
									/>
								);
							})}
						</RadioGroup>
					</FormControl>
				</Stack>
				<Stack direction="row" justifyContent="space-between" gap="0 20px">
					<Button
						variant="outlined"
						size="medium"
						onClick={handleClose}
						style={{ flex: 1 }}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						size="medium"
						onClick={() => chooseType(selectedType)}
						style={{ flex: 1 }}
					>
						Choose
					</Button>
				</Stack>
			</Box>
		</Modal>
	);
};
