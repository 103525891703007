export const ROUTES = {
	COLLECTIONS: '/',
	COLLECTION: 'collection',
	LOGIN: '/login',
};

export const API_ROUTES = {
	COLLECTIONS: '/admin/collections',
	SEARCH_ADDRESS: '/admin/search/byAddress',
	SEARCH_BY_FILTERS: '/admin/search/byFilters',
	LOGIN: '/auth/login',
	COLLECTIONS_ORDER: '/admin/collections/order'
};
