import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DetectPercent, formatToUSD, formatCurrency } from 'helper';
import { IAddress } from 'types';
import { Delete as DeleteIcon } from '@mui/icons-material';

export const Token = ({ token, onDelete }: { token: IAddress; onDelete?: () => void }) => {
	return (
		<Box
			sx={theme => ({
				borderRadius: 1,
				background: '#dee0e1',
				color: theme.palette.secondary.light,
				padding: 1,
				marginBottom: 1,
			})}
		>
			<Stack direction="row" alignItems={'center'} gap="0 12px">
				<img
					src={token.imageUrl || '/token-icon-stub.svg'}
					alt={token.name}
					style={{ width: '60px', height: '60px', objectFit: 'contain' }}
				/>
				<Stack flex={1}>
					<Stack
						sx={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: '0 20px',
						}}
					>
						<Stack direction="row" gap="0 20px">
							<Typography variant="body1" sx={{ fontWeight: 600 }}>
								{token.name}
							</Typography>
							<Typography>{formatToUSD(token.priceUsd, 6)}</Typography>
						</Stack>
						{onDelete && (
							<IconButton onClick={onDelete}>
								<DeleteIcon color="error" />
							</IconButton>
						)}
					</Stack>
					<Stack sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: '0 12px', flex: 1 }}>
						<Stack
							sx={{
								flexDirection: 'row',
								alignItems: 'center',
								flexWrap: 'nowrap',
								gap: '0 4px',
							}}
						>
							<Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
								24H Price Change:
							</Typography>
							<DetectPercent percent={token.priceChange.h24} variant="body2" />
						</Stack>
						<Typography
							variant="body2"
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '0 4px',
								whiteSpace: 'nowrap',
							}}
						>
							Liquidity: <span>{formatCurrency(token.liquidityUsd)}</span>
						</Typography>
						<Typography
							variant="body2"
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '0 4px',
								whiteSpace: 'nowrap',
							}}
						>
							24H Volume: <span>{formatCurrency(token.volume.h24)}</span>
						</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
};
